import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Features from "../components/features"
import FullCTA from "../components/fullCTA"
import CTA from "../components/cta"
import { BodyText } from "../components/bodyText"
import Hero from "../components/hero"
import { Sort } from "../services/utils"

const LandingPage = ({ data }) => {
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url
  const ctas = Sort(article.ctas || [], article.ctasSort || "")

  return (
    <Layout meta={article.pageMetaData || { title: article.name }}>
      <Hero
        data={article.hero}
        className={`landing ${article.url}`}
        title={article.hero.title}
        options={{ gravity: article.hero.image.gravity }}
      />
      <BodyText
        article={article}
        className="pt-xl"
        align="left"
        landing={true}
      />
      <Features features={article.features} startSide="left" />
      {ctas &&
        ctas.map(cta => {
          return cta.full ? (
            <FullCTA data={cta} />
          ) : (
            <div className="container pb-xl">
              <div className="inner">
                <CTA data={cta} color="light" />
              </div>
            </div>
          )
        })}
    </Layout>
  )
}

export default LandingPage

export const query = graphql`
  query LandingQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        subTitle
        text
        url
        hero {
          ...Hero
        }
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        pageMetaData {
          ...Meta
        }
        stylists {
          ...Stylist
        }
        stylistsSort
        testimonials {
          ...Testimonial
        }
      }
    }
  }
`
